<template>
  <v-container
      class="home"
      fluid
  >
    <v-row no-gutters>
      <v-col md="4" sm="12" class="d-flex"><v-img src="@/assets/thumbup.jpg" alt="thumbs up"/></v-col>
      <v-col md="8" sm="12" class="hero home-title__container">
        <div class="product-title">
          <v-img class="product-img" src="@/assets/fluidGFBlue-min.png" alt="Fluid GF"/>
        </div>
<!--				<h1 class="home-title">Human <span class="hero-highlight">AMNIOTIC LIQUID</span> Covered by<br> Insurance Including <span class="hero-highlight">MEDICARE</span></h1>-->
        <div class="hero-sub col-sm-12">
<!--          <p class="">Focused on <span class="hero-highlight">PATIENT</span> and <span class="hero-highlight">PRACTICE</span> success,-->
<!--          BioLab Amniotic Fluid GF™ will be a game-changer for you and your patients.</p>-->
          <p>BioLab Fluid GF™ is an amniotic liquid allograft derived from the amniotic fluid within the amniotic sac used to protect, lubricate and cushion.</p>

          <div class="check-container text-left mb-4">
            <div class=""><v-icon class="text-color-orange details-header__check">fan fa-check</v-icon> Non-surgical</div>
            <div class=""><v-icon class="text-color-orange details-header__check">fan fa-check</v-icon> Non-opioid</div>
            <div class=""><v-icon class="text-color-orange details-header__check">fan fa-check</v-icon> Non-steroidal</div>
          </div>
<!--          <v-btn-->
<!--              x-large-->
<!--              :color="'#00aeef' || '#fb7c34'"-->
<!--              class="play-video-button"-->
<!--              href="https://vimeo.com/647509035/99c8160b84"-->
<!--              target="_blank"-->
<!--          >-->
<!--            Play Video <i class="fas fa-play" :style="{ color: 'white', 'margin-left': '1rem' }" />-->
<!--          </v-btn>-->
          <div></div>
        </div>
      </v-col>
    </v-row>
    <div class="video-row">
      <div class="col-xs-12 video-column">
        <div class="product-title">
          <v-img class="product-img" :style="{ 'max-width': '390px' }" src="@/assets/membraneWrapLogo.png" alt="Membrane Wrap Logo"/>
        </div>
        <div class="product-description spacer">
          BioLab Sciences Membrane Wrap™ is a unique DUAL- LAYERED dehydrated human amnion membrane (dHAM) allograft composed primarily of a connective tissue matrix. It is minimally manipulated, preserving the properties that it exhibits in its natural state¹,². The presence of the connective tissue matrix aids in supporting and protecting the wound.
        </div>
        <div class="side-by-side">
<!--          <ul class="product-list">-->
<!--            <div class="check-container text-left mb-4">-->
<!--              <div class=""><v-icon class="text-color-orange details-header__check">fan fa-check</v-icon> Faster healing</div>-->
<!--              <div class=""><v-icon class="text-color-orange details-header__check">fan fa-check</v-icon> Less scarring</div>-->
<!--              <div class=""><v-icon class="text-color-orange details-header__check">fan fa-check</v-icon> Greater pain reduction</div>-->
<!--            </div>-->
<!--          </ul>-->
<!--          <div class="product-button">-->
<!--            <v-btn-->
<!--                x-large-->
<!--                :color="'#00aeef' || '#fb7c34'"-->
<!--                class="play-video-button"-->
<!--                href="https://vimeo.com/633073497/be1428e81a"-->
<!--                target="_blank"-->
<!--            >-->
<!--              Play Video <i class="fas fa-play" :style="{ color: 'white', 'margin-left': '1rem' }" />-->
<!--            </v-btn>-->
<!--          </div>-->
        </div>

      </div>
    </div>
    <div class="details d-sm-block d-md-flex">
      <v-col class="details__item col-sm-12 col-md-4">
        <div class="details-header"><v-icon class="text-color-green details-header__check">fan fa-check</v-icon>SAFE</div>
        <div class="details">
          Our products are safe and the quality is carefully controlled. Our products follow stringent FDA regulations and requirements and are classified as minimally manipulated under FDA regulation 21 CFR Part 1271 and section 361 of the PHS.
        </div>
      </v-col>
      <v-col class="details__item col-sm-12 col-md-4">
        <div class="details-header"><v-icon class="text-color-green details-header__check">fan fa-check</v-icon>EFFECTIVE</div>
        <div class="details">
          Amniotic liquid and tissue is abundant in growth factors and cytokines. Growth factors (GFs) are molecules capable of stimulating a variety of cellular processes.
        </div>
      </v-col>
<!--      <v-col class="details__item col-sm-12 col-md-4">-->
<!--        <div class="details-header"><v-icon class="text-color-green details-header__check">fan fa-check</v-icon>AFFORDABLE</div>-->
<!--        <div class="details">-->
<!--          Fluid GF™ was issued Q-Code Q4206 and Membrane Wrap™ was issued Q4205 by CMS and therefore can be reimbursed by Medicare making these products an affordable option for your patients.-->
<!--        </div>-->
<!--      </v-col>-->
      <v-col class="details__item col-sm-12 col-md-4">
        <div class="details-header"><v-icon class="text-color-green details-header__check">fan fa-check</v-icon>ETHICAL</div>
        <div class="details">
          BioLab Sciences amnio products use donated tissue from consenting mothers during scheduled Cesarian births. The tissue is screened and carefully controlled using FDA guidelines.
        </div>
      </v-col>
    </div>
    <div class="d-sm-block d-md-flex no-gutters">
      <v-col md="8" sm="12" class="hero">
        <h1><span class="hero-highlight">Partnering for PATIENT and PRACTICE Success</span> </h1>
        <div class="hero-sub col-md-8 col-sm-12">
					We specialize in integrating amniotic solutions into your practice by working with your team to provide product and billing support, as well as the tools to educate and inform your patients.
        </div>
      </v-col>
      <v-col md="4" sm="12"><v-img src="@/assets/assistance.jpg" /></v-col>
    </div>
    <div class="details d-sm-block d-md-flex">
      <v-col class="details__item col-sm-12 col-md-4">
        <div class="details-header"><v-icon class="text-color-green details-header__check">fan fa-check</v-icon>PRACTICE INTEGRATION</div>
        <div class="details">
          As you prepare to offer this solution to your patients, we work with your team to provide training and documentation on the product, the procedure, and patient communication.
        </div>
      </v-col>
      <v-col class="details__item col-sm-12 col-md-4">
        <div class="details-header"><v-icon class="text-color-green details-header__check">fan fa-check</v-icon>BILLING SUPPORT</div>
        <div class="details">
					We provide training and support for your billing team to submit reimbursement for Medicare and other insurance providers. We provide Insurance Verification services as well as a reimbursement guarantee.
        </div>
      </v-col>
      <v-col class="details__item col-sm-12 col-md-4">
        <div class="details-header"><v-icon class="text-color-green details-header__check">fan fa-check</v-icon>PATIENT OUTREACH</div>
        <div class="details">
          We provide educational materials and tools to support your patient education efforts, always ensuring your patients have the knowledge they need to make an informed decision.
        </div>
      </v-col>
    </div>
    <!--
    <v-row no-gutters class="mt-4">
      <v-col md="4" sm="12"><v-img src="@/assets/survey.jpg" /></v-col>
      <v-col md="8" sm="12" class="hero"><h1 class="hero__callout">Hear what doctors, nurses, and patients have to say about BioLab Fluid GF™</h1></v-col>
    </v-row>
    <div class="details d-flex flex-wrap">
      <v-col class="col-xs-12 col-sm-6 col-md-4 details__item">
        <div class="details-header">Patient Advocate</div>
        <div class="details">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/hb-YQQ4nahY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </v-col>
      <v-col class="col-xs-12 col-sm-6 col-md-4 details__item">
        <div class="details-header">Patient</div>
        <div class="details">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/cEVxYQ9tHx0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </v-col>
      <v-col class="col-xs-12 col-sm-6 col-md-4 details__item">
        <div class="details-header">Clinic Owner</div>
        <div class="details">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/-fv9JWnrrLQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </v-col>
      <v-col class="col-xs-12 col-sm-6 col-md-4 details__item">
        <div class="details-header">Orthopedic Surgeon</div>
        <div class="details">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/bEJ4hyBRnKY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </v-col>
      <v-col class="col-xs-12 col-sm-6 col-md-4 details__item">
        <div class="details-header">Nurse Practitioner</div>
        <div class="details">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/Ptwhzo3eoo4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </v-col>
      <v-col class="col-xs-12 col-sm-6 col-md-4 details__item">
        <div class="details-header">Integrated Medicine Practice Owner</div>
        <div class="details">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/YFDjWuXhfMY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </v-col>
    </div>
    -->
    <hr>
    <div class="clinical-studies col-sm-12">
      <div class="details-header">CLINICAL STUDIES AND JOURNAL ARTICLES OVERVIEW</div>
      <p>The medial literature has demonstrated over the last 100 years that using placental allografts promotes healing of many conditions.</p>

      <div class="journals">
        <!-- Pain Medicine -->
        <div class="journal col-xs-12 col-md-6 ">
          <div class="img">
            <v-img src="@/assets/aapmLogo.jpg" alt="the american academy of pain medicine"/>
          </div>
          <div class="content">
            <div class="title text-color-orange">
              Amniotic Fluid May be a Safe and Effective Alternative to Hyaluronic Acid for Osteoarthritis Pain:  Interim Results
            </div>
            <div class="description">
              <p>
                An Amniotic fluid based injection in this study offered pain and functional improvement that was greater at 13 weeks than at 30 days and appears to offer long-lasting relief at higher levels.
              </p>
              <p>
                Results observed in the first 170 amniotic fluid-treated patients showed their VAS and WOMAC scores improved an average of 68.1% (44mm) and 70.9% (812mm) respectively, at 30 days. Improvements increased at 90 days to 82% for WOMAC and 74% for VAS.
              </p>
            </div>
            <div class="action">
              <a class="download-link"
                  target="_blank"
                  href="https://firebasestorage.googleapis.com/v0/b/emsbio-19cd7.appspot.com/o/American%20Academy%20of%20Pain%20Medicine.pdf?alt=media&token=8147f8fe-cbcf-4fd5-b9be-b4b1abcaf755"
              >Download PDF</a>
            </div>
          </div>
        </div>

        <!-- Russell Health -->
        <div class="journal col-xs-12 col-md-6 ">
          <div class="img">
            <v-img src="@/assets/iwjlogo-min.jpeg" alt="International Wound Journal Logo"/>
          </div>
          <div class="content">
            <div class="title text-color-orange">
              Human amniotic membrane allograft, a novel treatment for chronic diabetic foot ulcers
            </div>
            <div class="description">
              <p>
                This meta‐analysis of randomised controlled trials showed that human amniotic membrane plus SOC is effective and safe in treating patients with chronic DFUs, which can significantly increase wound healing rate and reduce complete healing time.
              </p>
            </div>
            <div class="action">
              <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7949407/" target="_blank">Click to see the full meta-analysis</a>
            </div>
          </div>
        </div>

        <!-- Progressive Pain & Rehabilitation -->
        <div class="journal col-xs-12 col-md-6 ">
          <div class="img">
            <v-img src="@/assets/pp&rLogo.jpg" alt="Progressive Pain and Rehabilitation"/>
          </div>
          <div class="content">
            <div class="title text-color-orange">
              Clinical Study:  Amniotic Fluid for Osteoarthritis
            </div>
            <div class="description">
              <p>
                This study found that the results of treatment with Amniotic Allograft were superior to Hyaluronic Acid and placebo in this setting. We found significant differences between AA and HA at 3 months, including EQ-5D-5L pain and anxiety subsets; KOOS pain, symptoms, and ADL subsets; and VAS scores for overall pain, pain during strenuous work, and pain during normal daily living.
              </p>
            </div>
            <div class="action">
              <a href="https://drnashcares.com/amniotic-fluid-for-osteoarthritis/" target="_blank">Click to see second study for Amniotic Fluid for Osteoarthritis</a>
            </div>
          </div>
        </div>

        <!-- WOSM -->
        <div class="journal col-xs-12 col-md-6 ">
          <div class="img">
            <v-img src="@/assets/prsgloballogo-min.png" alt="PRS Global Logo"/>
          </div>
          <div class="content">
            <div class="title text-color-orange">
              Amnion Membrane in Diabetic Foot Wounds:  A Meta-analysis
            </div>
            <div class="description">
              <p>
                The current meta-analysis indicates that the treatment of diabetic foot ulcers with amniotic membrane improves healing rates in diabetic foot ulcers.
              </p>
            </div>
            <div class="action">
              <a href="https://www.researchgate.net/publication/316496687_Amnion_Membrane_in_Diabetic_Foot_Wounds_A_Meta-analysis" target="_blank">Click to read the Full Article</a>
            </div>
          </div>
        </div>
      </div>

    </div>
    <hr>
<!--    <div class="patient-outcomes d-sm-block d-md-flex">-->
<!--      <v-col class="col-xs-12 col-md-3">-->
<!--        <v-img src="@/assets/bones.png" alt="bones"/>-->
<!--      </v-col>-->
<!--      <v-col class="col-xs-12 col-md-9 item">-->
<!--        <div class="details-header">Patient Outcomes</div>-->
<!--        <div class="details">-->
<!--          <ul>-->
<!--            <li v-for="outcome in outcomes" class="patient-outcomes__link" :key="outcome.title"><a :href="outcome.file" target="_blank">{{outcome.title}}</a></li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </v-col>-->
<!--    </div>-->
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'BioLab Sciences Fluid GF Regenerative Pain Treatment',
    meta: [
      {
        name: 'description',
        // content: 'Fluid GF™ is a safe, effective, affordable pure amniotic fluid injection to relieve joint pain and inflammation and support the repair of soft tissue that is covered by Medicare with Q-Code Q4206.'
        content: 'Fluid GF™ is a safe and effective amniotic fluid product that helps to cushion, lubricate, and protect.'
      },
    ],
    // all titles will be injected into this template
    titleTemplate: '%s'
  },
  props: {},
  data() {
    return {
      outcomes: [
        {
          title: 'Fluid GF Orthopedic Treatment Outcome 49-year-old male Back/Sciatic Nerve Pain',
          file: 'https://firebasestorage.googleapis.com/v0/b/emsbio-19cd7.appspot.com/o/Fluid%20Flow%20Orthopedic%20Treatment%20Outcome%20June%202019%2049%20year%20old%20male.pdf?alt=media&token=a3ba9dcb-b5b7-400a-8895-ed1314b4db46',
        },
        {
          title: 'Fluid GF Orthopedic Treatment Outcome 80-year-old male Ankle',
          file: 'https://firebasestorage.googleapis.com/v0/b/emsbio-19cd7.appspot.com/o/Fluid%20Flow%20Orthopedic%20Treatment%20Outcome%2080-year-old%20male%20Ankle.pdf?alt=media&token=4ffa006a-4e54-48c6-8ce7-46d80f03f447',
        },
        {
          title: 'Fluid GF Amnio Breathe Treatment Outcome 15-year-old male Asthma',
          file: 'https://firebasestorage.googleapis.com/v0/b/emsbio-19cd7.appspot.com/o/Fluid%20Flow%20Amnio%20Breathe%20Treatment%20Outcome%2015-year-old%20male%20Asthma.pdf?alt=media&token=ed126a0d-a470-428b-bbd9-eb071230213b',
        },
        {
          title: 'Fluid GF Sexual Dysfunction Treatment Outcome 40-year-old male',
          file: 'https://firebasestorage.googleapis.com/v0/b/emsbio-19cd7.appspot.com/o/Fluid%20Flow%20Sexual%20Disfunction%20Treatment%20Outcome%2040-year-old%20male.pdf?alt=media&token=b560a7fe-a564-4994-9d12-562c6af8bee6',
        }
      ]
    };
  },
};
</script>




<style lang="scss" scoped>
.product-description.spacer {
  padding: 1rem 0 2rem 1rem;
}
.side-by-side {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  border-bottom: 1px solid #252261;
  .product-button {
    padding: .5rem;
  }
}
.play-video-button {
  color: white;
}
.product-title {
  .product-img {
    max-width: 350px;
  }
}

  .home {
    padding: 0;
    &-title {
      line-height: 1;
      margin-bottom: 1rem;
      &__container {
        padding: 3rem 1rem!important;
      }
      @media only screen and (max-width: 600px) {
        &__container {
          padding: 1rem!important;
        }
      }
    }
  }

  .video-row {
    display: flex;
    flex-wrap: wrap;
    .video-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .journals {
    display: flex;
    flex-wrap: wrap;
  }
  .journal {
    display: flex;
    .img {
      width: 100px;
      margin-right: 1rem;
    }
  }

  .hero {
    &__callout {
      padding: 2rem;
    }
  }
  .patient-outcomes {
    .header {
      font-size: 18px;
    }
    &__link {
      margin: 20px 0;
    }
  }

  .buy-now-button {
    max-width: 200px;
    color: white;
  }
</style>
